import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Head from 'next/head';

const imagePreloaderPropertyTypes = {
  finalFetchpriority: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  placeholderFetchpriority: PropTypes.string,
  preloadMediaQuery: PropTypes.string,
  sizes: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
};

type ImagePreloaderPropertyTypes = InferProps<
  typeof imagePreloaderPropertyTypes
>;

const ImagePreloader = ({
  src,
  placeholder,
  sizes,
  srcSet,
  preloadMediaQuery,
  placeholderFetchpriority,
  finalFetchpriority,
} : ImagePreloaderPropertyTypes) => (
  <Head>
    <link
      as="image"
      // eslint-disable-next-line react/no-unknown-property
      fetchpriority={placeholderFetchpriority ?? 'low'}
      href={placeholder}
      media={preloadMediaQuery ?? undefined}
      rel="preload"
    />
    <link
      as="image"
      // eslint-disable-next-line react/no-unknown-property
      fetchpriority={finalFetchpriority ?? 'low'}
      href={src}
      imageSizes={sizes ?? undefined}
      imageSrcSet={srcSet ?? undefined}
      media={preloadMediaQuery ?? undefined}
      rel="preload"
    />
  </Head>
);

ImagePreloader.propTypes = imagePreloaderPropertyTypes;

export default ImagePreloader;
