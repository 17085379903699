import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import breakpoints from './Theme/breakpoints';
import { mediumRoundedCorners } from './RoundedCorners';

interface ImageContainerProperties {
  height?: string;
  maxHeight?: string;
  objectFit?: string;
  showOnlyOn?: string;
  width?: string;
  padding?: string;
  disableRoundedCorners?: boolean;
}

const ImageContainer = styled.div<ImageContainerProperties>`
  ${({ width }) => (width && `
    width: ${width};
  `)};
  ${({ height }) => (height && `
    height: ${height};
  `)};
  ${({ padding }) => (padding && `
    padding: ${padding};
  `)};

  /* stylelint-disable-next-line selector-type-no-unknown */
  progressive-img, img {
    ${({ width }) => (width && `
      width: ${width};
      --img-width: ${width};
    `)}
    ${({ objectFit }) => (objectFit && `
      object-fit: ${objectFit};
      --img-object-fit: ${objectFit};
    `)}
    ${({ height }) => (height && `
      height: ${height};
      --img-height: ${height};
    `)}
    ${({ maxHeight }) => (maxHeight && `
      max-height: ${maxHeight};
      --img-max-height: ${maxHeight};
    `)}

    @media (min-width: ${breakpoints.sm}px) {
      ${({ disableRoundedCorners }) => (disableRoundedCorners
    ? 'border-radius: 0' : mediumRoundedCorners)};
      overflow: hidden;
    }
  }

  display: ${({ showOnlyOn }) => (showOnlyOn === 'desktop' ? 'none' : 'block')};

  @media (min-width: ${breakpoints.sm}px) {
    ${({ disableRoundedCorners }) => (disableRoundedCorners
    ? 'border-radius: 0' : mediumRoundedCorners)};
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.lg}px) {
    display: ${({ showOnlyOn }) => (showOnlyOn === 'mobile'
    ? 'none' : 'block')};
  }
`;

const NoScript = styled.noscript`
  min-width: 100%;
`;

interface ImageProperties {
  alt: string;
  className?: string;
  height?: string;
  image: ReactNode;
  maxHeight?: string;
  objectFit?: string;
  showOnlyOn?: string;
  sizes?: string;
  src: string;
  srcSet?: string;
  width?: string;
  padding?: string;
  disableRoundedCorners?: boolean;
}

const Image = ({
  alt,
  className,
  height = 'auto',
  image,
  maxHeight = 'none',
  objectFit = 'contain',
  showOnlyOn,
  sizes,
  src,
  srcSet,
  width = 'auto',
  padding,
  disableRoundedCorners,
}: ImageProperties) => (
  <ImageContainer
    className={className}
    height={height}
    maxHeight={maxHeight}
    objectFit={objectFit}
    showOnlyOn={showOnlyOn}
    width={width}
    padding={padding}
    disableRoundedCorners={disableRoundedCorners}
  >
    {image}
    <NoScript>
      <img
        alt={alt}
        src={src}
        sizes={sizes}
        srcSet={srcSet}
      />
    </NoScript>
  </ImageContainer>
);

export default Image;
