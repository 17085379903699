import { css } from '@emotion/react';
import breakpoints from './Theme/breakpoints';

const minimalRoundedCorners = css`
  @media (min-width: ${breakpoints.xssm}px) {
    border-radius: 2px;
  }
`;

const mediumRoundedCorners = css`
  @media (min-width: ${breakpoints.xssm}px) {
    border-radius: 4px;
  }
`;

const topRoundedCorners = css`
  @media (min-width: ${breakpoints.xssm}px) {
    border-radius: 4px 4px 0 0;
  }
`;

const bottomRoundedCorners = css`
  @media (min-width: ${breakpoints.xssm}px) {
    border-radius: 0 0 4px 4px;
  }
`;

export {
  mediumRoundedCorners,
  minimalRoundedCorners,
  topRoundedCorners,
  bottomRoundedCorners,
};
